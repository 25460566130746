import { getClassNames, isBrowser } from '@websolutespa/bom-core';
import { useLabel } from '@websolutespa/bom-mixer-hooks';
import { IconLlmPin } from '@websolutespa/bom-mixer-icons';
import { LlmChunkTripadvisor, LlmChunkTripadvisorGroup, LlmChunkTripadvisorItem } from '../../../types';
import { useLlm } from '../../../useLlm/useLlm';
import { Cta } from '../Cta/cta';

export const TripAdvisor = (props: LlmChunkTripadvisorItem | LlmChunkTripadvisor) => {
  const label = useLabel();
  const { decorateUrl } = useLlm(state => state.actions);
  const onBookNow = async (event: React.MouseEvent) => {
    if (isBrowser) {
      event.preventDefault();
      window.open(props.book, '_blank');
    }
  };
  const onGoToWebsite = async (event: React.MouseEvent) => {
    if (isBrowser) {
      event.preventDefault();
      const url = await decorateUrl(props, props.url);
      window.open(url, '_blank');
    }
  };
  return (
    <div className="llm__tripadv">
      <div className="llm__tripadv-inner">
        {props.image && (
          <div className="llm__tripadv-media">
            <img src={props.image} alt="" />
          </div>
        )}
        <div className="llm__tripadv-content">
          {props.title && (
            <div className="llm__tripadv-title">
              {props.title}
            </div>
          )}
          {props.rating && (
            <div className="llm__tripadv-rating">
              <span className="llm__tripadv-dots"><span style={{ width: `${props.rating / 5 * 100}%` }} /></span>
              {props.reviews && (
                <span>{props.reviews} {label('llm.reviews')}</span>
              )}
            </div>
          )}
          <div className="llm__tripadv-info">
            <span className="llm__tripadv-cuisine">{`${props.priceRange}, `}{props.cuisine}</span>
          </div>
          {props.address && (
            <div className="llm__tripadv-location">
              <IconLlmPin /> {props.address}
            </div>
          )}
          <div className="llm__tripadv-ctas">
            {props.book && (
              <Cta type="button" label={label('llm.bookNow')} onClick={onBookNow} />
            )}
            <Cta type="text" label={label('llm.goToWebsite')} onClick={onGoToWebsite} />
          </div>
          <div className="llm__tripadv-icon">
            <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Tripadvisor</title><path d="M12.006 4.295c-2.67 0-5.338.784-7.645 2.353H0l1.963 2.135a5.997 5.997 0 0 0 4.04 10.43 5.976 5.976 0 0 0 4.075-1.6L12 19.705l1.922-2.09a5.972 5.972 0 0 0 4.072 1.598 6 6 0 0 0 6-5.998 5.982 5.982 0 0 0-1.957-4.432L24 6.648h-4.35a13.573 13.573 0 0 0-7.644-2.353zM12 6.255c1.531 0 3.063.303 4.504.903C13.943 8.138 12 10.43 12 13.1c0-2.671-1.942-4.962-4.504-5.942A11.72 11.72 0 0 1 12 6.256zM6.002 9.157a4.059 4.059 0 1 1 0 8.118 4.059 4.059 0 0 1 0-8.118zm11.992.002a4.057 4.057 0 1 1 .003 8.115 4.057 4.057 0 0 1-.003-8.115zm-11.992 1.93a2.128 2.128 0 0 0 0 4.256 2.128 2.128 0 0 0 0-4.256zm11.992 0a2.128 2.128 0 0 0 0 4.256 2.128 2.128 0 0 0 0-4.256z" /></svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export const TripAdvisorGroup = (props: LlmChunkTripadvisorGroup) => {
  const classNames = getClassNames('llm__tripadvisor-group');
  return (
    <div className={classNames}>
      {props.items.map((item, i) => (
        <TripAdvisor key={i} {...item} />)
      )}
    </div>
  );
};

