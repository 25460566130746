import { createContext, useContext, useRef } from 'react';
import { createStore, useStore } from 'zustand';
import { StreamResponse } from '../types';
import { useLlm } from './useLlm';

export type ScrollToOptions = {
  offset?: number;
  immediate?: boolean;
  lock?: boolean;
};

export type ScrollerRef = {
  scrollTo: (target: string | number | HTMLElement, options?: ScrollToOptions) => void;
  stop: () => void;
  start: () => void;
  on(event: string, callback: Function): any;
  off(event: string, callback: Function): any;
};

export type LlmViewOptions = {
  threadId?: string;
  preview?: boolean;
  opened?: boolean;
  dismissable?: boolean;
  skipCustomIntro?: boolean;
  embedded?: boolean;
};

export type LlmViewProps = {
  opened: boolean;
  dismissable: boolean;
  skipCustomIntro: boolean;
  embedded: boolean;
  introed: boolean;
  hidden: boolean;
  fixed: boolean;
  idle: boolean;
  visible: boolean;
  direction: number;
};

export type LlmViewActions = {
  initView: () => Promise<void>;
  initIntersection: (element: Element | null) => void;
  send: (message: string, onMessage?: (response: StreamResponse) => void, onEnd?: (response: StreamResponse) => void) => Promise<void>,
  open: () => void;
  setIntroed: (introed: boolean) => void;
  setVisible: (visible: boolean) => void;
  setScroller: (scroller?: ScrollerRef) => void;
  scrollerStop: () => void;
  scrollerStart: () => void;
  scrollToBottom: () => void;
};

export type LlmViewState = LlmViewProps & {
  actions: LlmViewActions;
};

export type LlmViewStore = ReturnType<typeof createLlmViewStore>;

const createLlmViewStore = ({
  threadId,
  dismissable = true,
  skipCustomIntro = false,
  opened,
  embedded,
  send,
  setEmbed,
}: LlmViewOptions & {
  send: (message: string, onPrompt?: () => void, onMessage?: (response: StreamResponse) => void, onEnd?: (response: StreamResponse) => void) => Promise<void>;
  setEmbed: (element: Element, active: boolean) => void;
}) => {

  const props: LlmViewProps = {
    opened: opened != null ? opened :
      (embedded != null || threadId != null ? true : false),
    dismissable: embedded ? false : dismissable,
    skipCustomIntro: skipCustomIntro,
    embedded: embedded || false,
    introed: skipCustomIntro ? true : false,
    hidden: false,
    fixed: false,
    idle: true,
    visible: false,
    direction: 0,
  };

  let scroller_: ScrollerRef | undefined = undefined;

  const useStore = createStore<LlmViewState>((set, get) => {

    const onScrollerScroll = (event: any) => {
      const { direction, targetScroll, limit } = event;
      // console.log('onScrollerScroll', direction, targetScroll, limit);
      const scrolled = targetScroll > 100;
      const fixed = direction == -1 && scrolled;
      const hidden = direction == 1 && scrolled;
      const state = get();
      if (state.hidden !== hidden || state.fixed !== fixed || state.direction !== direction) {
        set(state => ({ hidden, fixed, direction }));
      }
      // console.log('onScrollerScroll', event, event.progress, event.direction);
    };

    return {
      ...props,
      actions: {
        initView: async () => {
          const state = get();
          // handle idle times
          const setIdleTimeout = () => {
            return setTimeout(() => {
              set(() => ({ idle: true }));
            }, 60000) as unknown as number;
          };
          const handleIdleTimes = () => {
            let to: number = setIdleTimeout();
            let lastScrollTop: number = 0;
            window.addEventListener('scroll', () => {
              const scrollTop = window.scrollY || document.documentElement.scrollTop;
              let direction = 0;
              if (scrollTop > lastScrollTop) {
                direction = 1;
              } else if (scrollTop < lastScrollTop) {
                direction = -1;
              }
              lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
              if (direction === 1) {
                if (to) {
                  clearTimeout(to);
                }
                set(() => ({ idle: false }));
                to = setIdleTimeout();
              }
            });
          };
          setTimeout(() => {
            set(() => ({ idle: false }));
            handleIdleTimes();
          }, 2000);
        },
        initIntersection: (element: Element | null) => {
          // console.log('initIntersection', element);
          const handleIntersection = () => {
            // console.log('handleIntersection', element);
            if (!element) {
              return;
            }
            if ('IntersectionObserver' in window) {
              const observer = new IntersectionObserver((entries, observer) => {
                entries.forEach((entry) => {
                  // console.log('IntersectionObserver', entry.target, entry.isIntersecting);
                  const state = get();
                  state.actions.setVisible(entry.isIntersecting);
                  if (embedded) {
                    setEmbed(entry.target, entry.isIntersecting);
                  }
                });
              }, {
                root: document,
                rootMargin: '50px',
                threshold: [0.01, 0.99],
              });
              observer.observe(element);
            } else {
              const state = get();
              state.actions.setVisible(true);
            }
          };
          handleIntersection();
        },
        send: async (prompt, onMessage, onEnd) => {
          await send(prompt, () => {
            set({ skipCustomIntro: true });
            setTimeout(() => {
              /*
              const state = get();
              state.actions.scrollToBottom();
              */
              if (scroller_) {
                const userMessages = Array.from(document.querySelectorAll('.llm.-open .llm__message.llm__message--user'));
                // console.log(userMessages);
                if (userMessages.length > 0) {
                  const lastUserMessage = userMessages.pop() as HTMLElement;
                  lastUserMessage.scrollIntoView({
                    behavior: 'instant',
                    block: 'start',
                    inline: 'start',
                  });
                  /*
                  scroller_.scrollTo(lastUserMessage, {
                    immediate: true,
                  });
                  */
                }
              }
            }, 50);
          }, (response) => {
            if (typeof onMessage === 'function') {
              onMessage(response);
            }
          }, (response) => {
            if (typeof onEnd === 'function') {
              onEnd(response);
            }
          });
        },
        open: () => set(state => ({ opened: !state.opened })),
        setIntroed: (introed: boolean) => {
          const state = get();
          introed ? state.actions.scrollerStart() : state.actions.scrollerStop();
          set(state => ({ introed }));
        },
        setVisible: (visible: boolean) => set(state => ({ visible })),
        setScroller: (scroller?: ScrollerRef) => {
          if (scroller_ !== scroller) {
            if (scroller_) {
              scroller_.off('scroll', onScrollerScroll);
            }
            scroller_ = scroller;
            if (scroller) {
              scroller.on('scroll', onScrollerScroll);
            }
          }
        },
        scrollerStop: () => {
          if (scroller_) {
            scroller_.stop();
          }
        },
        scrollerStart: () => {
          if (scroller_) {
            scroller_.start();
          }
        },
        scrollToBottom: () => {
          if (scroller_) {
            scroller_.scrollTo('bottom', {
              immediate: true,
            });
          }
        },
      },
    };
  });
  return useStore;
};

export const LlmViewContext = createContext<LlmViewStore | null>(null);

type LlmViewProviderProps = React.PropsWithChildren<LlmViewOptions>;

function LlmViewProvider({ children, ...props }: LlmViewProviderProps) {
  const { send, setEmbed } = useLlm(state => state.actions);
  const storeRef = useRef<LlmViewStore>();
  if (!storeRef.current) {
    storeRef.current = createLlmViewStore({ ...props, send, setEmbed });
  }
  return storeRef.current && (
    <LlmViewContext.Provider value={storeRef.current}>
      {children}
    </LlmViewContext.Provider>
  );
}

function useLlmView<T>(selector: (state: LlmViewState) => T): T {
  const store = useContext(LlmViewContext);
  if (!store) throw new Error('Missing LlmContext.Provider in the tree');
  return useStore(store, selector);
}

export { LlmViewProvider, useLlmView };

