import { getClassNames } from '@websolutespa/bom-core';
import { useCurrency, useLabel } from '@websolutespa/bom-mixer-hooks';
import { IconLlmChevronRightCircle } from '@websolutespa/bom-mixer-icons';
import { ReactNode } from 'react';
import { slugify } from 'transliteration';
import { LlmChunkProduct, LlmChunkProductItem } from '../../../types';

export const ProductWrapper = ({ children, ...props }: (LlmChunkProductItem | LlmChunkProduct) & { children: ReactNode }) => {
  // const label = useLabel();
  const classNames = getClassNames('llm__product', `llm__product--${props.productType}`);
  return props.url ? (
    <a className={classNames} href={props.url} target="_blank" rel="noreferrer" aria-label={props.title}>
      {children}
    </a>
  ) : (
    <div className={classNames}>
      {children}
    </div>
  );
};

const limitAvailability = false;

export const Product = (props: LlmChunkProductItem | LlmChunkProduct) => {
  const label = useLabel();
  const currency = useCurrency();
  function asNumber(value: string | number | undefined): number {
    return value ? Number(value) : 0;
  }
  const price = asNumber(props.price);
  const fullPrice = asNumber(props.fullPrice) || price;
  const hasPrice = props.price != null;
  const availability = asNumber(props.availability);
  const hasAvailability = props.availability != null;
  const labelKey = props.label ? `-${slugify(props.label)}` : '';
  return (
    <ProductWrapper {...props}>
      {props.media.type === 'image' && (
        <div className="llm__product-media">
          <div className="llm__product-asset">
            <img src={props.media.src} alt="" />
          </div>
          {props.label && (
            <div
              className="llm__product-label"
              style={{
                background: `var(--llm-product-label${labelKey}-background, var(--llm-product-label-background))`,
                color: `var(--llm-product-label${labelKey}-foreground, var(--llm-product-label-foreground))`,
              }}
            >{props.label}</div>
          )}
        </div>
      )}
      <div className="llm__product-content">
        {props.sku && (
          <div className="llm__product-sku">{props.sku}</div>
        )}
        <div className="llm__product-grow">
          {props.title && (
            <div className="llm__product-title" dangerouslySetInnerHTML={{ __html: props.title }} />
          )}
          {props.abstract && (
            <div className="llm__product-abstract" dangerouslySetInnerHTML={{ __html: props.abstract }} />
          )}
        </div>
        {hasPrice && (
          <div className="llm__product-prices">
            <div className="llm__product-price">{currency(price)}</div>
            {fullPrice > price && (
              <div className="llm__product-fullprice">{currency(fullPrice)}</div>
            )}
          </div>
        )}
        {hasAvailability && (
          availability ? (
            limitAvailability ? (
              availability > 100 ? (
                <div className="llm__product-available">{label('llm.available')}</div>
              ) : (
                <div className="llm__product-available">{label('llm.availability', { availability: availability })}</div>
              )
            ) : (
              <div className="llm__product-available">{label('llm.availability', { availability: availability })}</div>
            )
          ) : (
            <div className="llm__product-soldout">{label('llm.soldOut')}</div>
          )
        )}
        {props.cta && (
          <div className="llm__product-cta">
            <span>{props.cta}</span> <IconLlmChevronRightCircle />
          </div>
        )}
      </div>
    </ProductWrapper>
  );
};
