/* eslint-disable comma-dangle */
/* eslint-disable quotes */

import { LlmMockThread } from "../types";
export const mockThread: LlmMockThread = [
  {
    "chunks": [
      "markdown\n",
    ],
    "role": "user",
  },
  {
    "chunks": [
      { "type": "string", "content": "Markdown\n" },
      { "type": "string", "content": "  \n" },
      { "type": "string", "content": "Header 1  \n" },
      { "type": "string", "content": "========  \n" },
      { "type": "string", "content": "  \n" },
      { "type": "string", "content": "Header 2  \n" },
      { "type": "string", "content": "--------  \n" },
      { "type": "string", "content": "  \n" },
      { "type": "string", "content": "### Header 3 ###  \n" },
      { "type": "string", "content": "#### Header 4 ####  \n" },
      { "type": "string", "content": "##### Header 5 #####  \n" },
      { "type": "string", "content": "###### Header 6 ######   \n" },
      { "type": "string", "content": "  \n" },
      { "type": "string", "content": "### Phrase Emphasis   \n" },
      { "type": "string", "content": "  \n" },
      { "type": "string", "content": "*italic*   \n" },
      { "type": "string", "content": "**bold**  \n" },
      { "type": "string", "content": "_italic_   \n" },
      { "type": "string", "content": "__bold__  \n" },
      { "type": "string", "content": "  \n" },
      { "type": "string", "content": "### Links  \n" },
      { "type": "string", "content": "  \n" },
      { "type": "string", "content": "An [example](https://www.gmail.com/ \"Title\")  \n" },
      { "type": "string", "content": "  \n" },
      { "type": "string", "content": "### Images  \n" },
      { "type": "string", "content": "![alt text](https://unsplash.com/photos/ovcE_Oh1Gu8/download?ixid=M3wxMjA3fDB8MXxzZWFyY2h8MTJ8fG1vbnRhbmF8ZW58MHx8fHwxNzE2Mzk0ODAyfDA&force=true&w=640 \"Title\")  \n" },
      { "type": "string", "content": "  \n" },
      { "type": "string", "content": "### Lists  \n" },
      { "type": "string", "content": "  \n" },
      { "type": "string", "content": "1.  Foo  \n" },
      { "type": "string", "content": "2.  Bar  \n" },
      { "type": "string", "content": "  \n" },
      { "type": "string", "content": "*   A list item.  \n" },
      { "type": "string", "content": "    With multiple paragraphs.  \n" },
      { "type": "string", "content": "*   Bar  \n" },
      { "type": "string", "content": "*   Abacus  \n" },
      { "type": "string", "content": "    * answer  \n" },
      { "type": "string", "content": "*   Bubbles  \n" },
      { "type": "string", "content": "    1.  bunk  \n" },
      { "type": "string", "content": "    2.  bupkis  \n" },
      { "type": "string", "content": "        * BELITTLER  \n" },
      { "type": "string", "content": "    3. burper  \n" },
      { "type": "string", "content": "*   Cunning  \n" },
      { "type": "string", "content": "  \n" },
      { "type": "string", "content": "### Blockquotes  \n" },
      { "type": "string", "content": "> Email-style angle brackets  \n" },
      { "type": "string", "content": "> are used for blockquotes.  \n" },
      { "type": "string", "content": "  \n" },
      { "type": "string", "content": "> > And, they can be nested.  \n" },
      { "type": "string", "content": "  \n" },
      { "type": "string", "content": "> ### Headers in blockquotes  \n" },
      { "type": "string", "content": ">   \n" },
      { "type": "string", "content": "> * You can quote a list.  \n" },
      { "type": "string", "content": "> * Etc.  \n" },
      { "type": "string", "content": "  \n" },
      { "type": "string", "content": "### Code Spans  \n" },
      { "type": "string", "content": "`<code>` spans are delimited by backticks.  \n" },
      { "type": "string", "content": "  \n" },
      { "type": "string", "content": "You can include literal backticks like `` `this` ``.  \n" },
      { "type": "string", "content": "  \n" },
      { "type": "string", "content": "### Preformatted Code Blocks  \n" },
      { "type": "string", "content": "  \n" },
      { "type": "string", "content": "This is a normal paragraph.  \n" },
      { "type": "string", "content": "  \n" },
      { "type": "string", "content": "    This is a preformatted  \n" },
      { "type": "string", "content": "    code block.  \n" },
      { "type": "string", "content": "  \n" },
      { "type": "string", "content": "```  \n" },
      { "type": "string", "content": "function sum(a, b) {  \n" },
      { "type": "string", "content": "  return a + b;  \n" },
      { "type": "string", "content": "}  \n" },
      { "type": "string", "content": "```  \n" },
      { "type": "string", "content": "  \n" },
      { "type": "string", "content": "### Horizontal Rules  \n" },
      { "type": "string", "content": "  \n" },
      { "type": "string", "content": "---  \n" },
      { "type": "string", "content": "  \n" },
      { "type": "string", "content": "* * *  \n" },
      { "type": "string", "content": "  \n" },
      { "type": "string", "content": "- - - -   \n" },
      { "type": "string", "content": "  \n" },
      { "type": "string", "content": "### Manual Line Breaks  \n" },
      { "type": "string", "content": "  \n" },
      { "type": "string", "content": "Roses are red,   \n" },
      { "type": "string", "content": "Violets are blue.  \n" },
      { "type": "string", "content": "  \n" },
    ],
    "role": "assistant",
  },
  {
    "chunks": [
      "events\n",
    ],
    "role": "user",
  },
  {
    "chunks": [
      "Fammi pensare...\n",
      "ecco una lista di eventi:\n",
      {
        "type": "eventItem",
        "id": "xxxx",
        "title": "Percorri la Bicipolitana di Pesaro con i tuoi bambini",
        "abstract": "Un’esperienza da non perdere se visiterai la città con i bambini, noleggia la tua bici e esplora Pesaro.",
        "place": "Pesaro",
        "url": "https://design.wslabs.it/llm",
        "image": "https://design.wslabs.it/llm/media/bicipolitana.jpg",
        "date": "15-16 giugno 2024",
        "time": "ore 20:00",
      },
      {
        "type": "eventItem",
        "id": "100",
        "title": "Non perderti The Magic Castle di Gradara!",
        "abstract": "The Magic Castle, l’evento per famiglie che ogni estate trasforma magicamente lo splendido borgo di Gradara.",
        "place": "Gradara",
        "url": "https://design.wslabs.it/llm",
        "image": "https://design.wslabs.it/llm/media/gradara.jpg",
        "date": "15-16 giugno 2024",
        "time": "ore 20:00",
      },
      {
        "type": "eventItem",
        "id": "xxxx",
        "title": "Trekking e panorami mozzafiato al San Bartolo",
        "abstract": "Scendendo lungo la costa Adriatica, si erge il San Bartolo, un meraviglioso parco naturale affacciato sul mare.",
        "place": "Parco San Bartolo",
        "url": "https://design.wslabs.it/llm",
        "image": "https://design.wslabs.it/llm/media/san-bartolo.jpg",
        "date": "15-16 giugno 2024",
        "time": "ore 20:00",
      },
      "Per qualunque altra cosa sono qui. 🙂\n",
    ],
    "role": "assistant",
  },
  {
    "chunks": [
      "event\n",
    ],
    "role": "user",
  },
  {
    "chunks": [
      "Dammi un momento...\n",
      "ecco un evento:\n",
      {
        "type": "event",
        "id": "234",
        "title": "Rossini Virtual 'Experience': esplora casa Rossini in AR",
        "abstract": "Lasciati guidare dallo stesso Gioacchino nella sua casa e scopri la sua storia attraverso giocosi aneddoti in AR.",
        "place": "Pesaro",
        "url": "https://design.wslabs.it/llm",
        "image": "https://source.unsplash.com/random/800x600/?nature",
        "date": "15-16 giugno 2024",
        "time": "ore 20:00",
      },
      "Serve altro?\n",
    ],
    "role": "assistant",
  },
  {
    "chunks": [
      "pois\n",
    ],
    "role": "user",
  },
  {
    "chunks": [
      "Bene!\n",
      "ecco alcuni punti di interesse:\n",
      {
        "type": "poiItem",
        "id": "111",
        "title": "Title",
        "abstract": "Lorem ipsum dolor sit amet.",
        "place": "Place",
        "image": "https://source.unsplash.com/random/800x600/?nature",
        "url": "https://design.wslabs.it/llm",
        "time": "time",
      },
      {
        "type": "poiItem",
        "id": "222",
        "title": "Title",
        "abstract": "Lorem ipsum dolor sit amet.",
        "place": "Place",
        "image": "https://source.unsplash.com/random/800x600/?nature",
        "url": "https://design.wslabs.it/llm",
      },
      "Serve altro? 🙂\n",
    ],
    "role": "assistant",
  },
  {
    "chunks": [
      "poi\n",
    ],
    "role": "user",
  },
  {
    "chunks": [
      "Certo!\n",
      "ecco un punto di interesse:\n",
      {
        "type": "poiItem",
        "id": "111",
        "title": "Title",
        "abstract": "Lorem ipsum dolor sit amet.",
        "place": "Place",
        "image": "https://design.wslabs.it/llm/media/rossini.jpg",
        "url": "https://design.wslabs.it/llm",
        "time": "time",
      },
      "Serve altro? 🙂\n",
    ],
    "role": "assistant",
  },
  {
    "chunks": [
      "tripadvisor\n",
    ],
    "role": "user",
  },
  {
    "chunks": [
      "Dammi un secondo...\n",
      "ecco alcuni ristoranti per te:\n",
      {
        "type": "tripadvisor",
        "id": "xxxx",
        "title": "L'Angolo di Mario",
        "rating": 4.8,
        "reviews": 2276,
        "priceRange": "€€-€€€",
        "cuisine": "Italiana, Pesce, Mediterranea",
        "address": "Via Nazario Sauro, 61100 Pesaro PU",
        "url": "https://design.wslabs.it/llm",
        "book": "https://design.wslabs.it/llm",
        "image": "https://design.wslabs.it/llm/media/tripadvisor-01.jpg",
      },
      {
        "type": "tripadvisor",
        "id": "xxxx",
        "title": "L'Amo Osteria di Mare",
        "rating": 4.2,
        "reviews": 287,
        "priceRange": "€€-€€€",
        "cuisine": "Italiana, Pesce, Mediterranea",
        "address": "Via Venturini 4/6, 61121 Pesaro PU",
        "url": "https://design.wslabs.it/llm",
        "book": "https://design.wslabs.it/llm",
        "image": "https://design.wslabs.it/llm/media/tripadvisor-02.jpg",
      },
    ],
    "role": "assistant",
  },
];
