import { getClassNames } from '@websolutespa/bom-core';
import { LlmChunkProductGroup } from '../../../types';
import { Grid, GridRow } from '../Grid/grid';
import { Product } from '../Product/product';

export const ProductGroup = (props: LlmChunkProductGroup) => {
  const classNames = getClassNames('llm__products');
  return (
    <div className={classNames}>
      <GridRow rowGap="2rem">
        {props.items.map((item, i) => (
          <Grid key={i} xs={6} md={4} lg={3}>
            <Product {...item} />
          </Grid>
        ))}
      </GridRow>
    </div>
  );
};

