import { useWindowSize } from '@websolutespa/bom-mixer-hooks';
import { FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { LlmSampleInputText } from '../../../types';
import { useLlm } from '../../../useLlm/useLlm';
import { useLlmView } from '../../../useLlm/useLlmView';
import { clamp } from '../../utils';

export const Suggestions = () => {

  useWindowSize();

  const app = useLlm(state => state.app);
  const { send } = useLlmView(state => state.actions);

  const swiperProps = {
    modules: [FreeMode],
    spaceBetween: 8,
    freeMode: true,
    slidesPerView: 'auto' as const,
    slidesOffsetAfter: clamp(20, 110),
    slidesOffsetBefore: clamp(20, 110),
  };

  const onClick = async (item: LlmSampleInputText) => {
    await send(item.sampleInputText, (response) => {
      // console.log('Suggestions.onMessage', response.chunks);
    }, (response) => {
      // console.log('Suggestions.onEnd', response);
    });
  };

  return (
    <div className="llm__suggestions">
      <Swiper {...swiperProps} onInit={swiper => swiper.update()}>
        {app?.contents.sampleInputTexts.map((s, j) => (
          <SwiperSlide key={j}>
            <button type="button" className="llm__pill" onClick={() => onClick(s)}>
              {s.sampleInputText}
            </button>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
