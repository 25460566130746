import { getClassNames } from '@websolutespa/bom-core';
import { useLabel } from '@websolutespa/bom-mixer-hooks';
import { motion } from 'framer-motion';
import { useLlm } from '../../../useLlm/useLlm';
import { useLlmView } from '../../../useLlm/useLlmView';
import { messageMapper } from '../../utils';
import { Canvas } from '../Canvas/canvas';
import { Blob } from './blob';

export const Trigger = () => {
  const app = useLlm(state => state.app);
  const opened = useLlmView(state => state.opened);
  const { open } = useLlmView(state => state.actions);
  const label = useLabel();
  const collapsedWelcomeText = messageMapper(app?.contents.collapsedWelcomeText);
  const collapsedWelcomeTextHover = messageMapper(app?.contents.collapsedWelcomeTextHover);
  return (
    <motion.nav
      initial={{ y: '200%', opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: '200%', opacity: 0 }}
      transition={{
        duration: .4,
      }}
      className="llm__trigger"
      aria-label="Trigger"
      onClick={() => open()}
    >
      <button type="button" className={getClassNames('llm__trigger-btn', { 'llm__trigger-btn--hover': app?.contents.collapsedWelcomeTextHover })}>
        <Blob />
        <span>{label('llm.button')}</span>
      </button>
      {!opened && (
        <div className="llm__trigger-popup -llm-t-body-2">
          <motion.div
            exit={{
              opacity: 0,
            }}>
            <Canvas />
            {collapsedWelcomeText}
            {app?.contents.collapsedWelcomeTextCta && (
              <button type="button" className="llm__trigger-cta">{app?.contents.collapsedWelcomeTextCta}</button>
            )}
          </motion.div>
          {collapsedWelcomeTextHover && (
            <motion.div
              exit={{
                opacity: 0,
              }}>
              <Canvas />
              {collapsedWelcomeTextHover}
            </motion.div>
          )}
        </div>
      )}
    </motion.nav>
  );
};

