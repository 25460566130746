
import { isBrowser } from '@websolutespa/bom-core';
import { motion, Variants } from 'framer-motion';
import { useLenis } from 'lenis/react';
import { useEffect, useRef } from 'react';
import { useLlm } from '../../../useLlm/useLlm';
import { useLlmView } from '../../../useLlm/useLlmView';
import { Hints } from '../Hints/hints';
import { Blob } from '../Trigger/blob';

const motionItem: Variants = {
  hidden: { y: '40px' },
  show: {
    y: 0,
    transition: {
      type: 'tween',
      duration: 1,
    },
  },
};

const motionBlob: Variants = {
  top: {
    y: '-15%',
  },
  bottom: {
    y: '0%',
    transition: {
      repeat: Infinity,
      repeatType: 'mirror',
      duration: 2,
      ease: 'easeInOut',
    },
  },
};

const stagger = {
  hidden: {},
  show: {
    transition: {
      staggerChildren: .3,
    },
  },
};

export const Intro = () => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const app = useLlm(state => state.app);
  const { send, setIntroed } = useLlmView(state => state.actions);
  const skipCustomIntro = useLlmView(state => state.skipCustomIntro);
  const embedded = useLlmView(state => state.embedded);
  const lenis = useLenis();

  useEffect(() => {
    if (isBrowser) {
      const onMessage = (event: MessageEvent) => {
        if (!event.data) {
          return;
        }
        const message = JSON.parse(event.data);
        switch (message.type) {
          case 'send':
            if (message.prompt && typeof message.prompt === 'string') {
              setIntroed(true);
              send(message.prompt, (response) => {
                // console.log('Prompt.onChunk', response.chunks);
              }, (response) => {
                // console.log('Prompt.onEnd', response);
              }).catch((error: any) => {
                console.log('Intro.send.error', error);
              });
            }
            break;
          case 'resize':
            if (iframeRef.current) {
              // iframeRef.current.style.height = message.size.height + 'px';
            }
            break;
          case 'introed':
            // console.log('onMessage.introed');
            setIntroed(message.introed);
            break;
        }
      };
      window.addEventListener('message', onMessage);
      // console.log('onMessage');
      return () => window.removeEventListener('message', onMessage);
    }
    return () => { };
  }, [setIntroed]);

  useEffect(() => {
    const onLenisScroll = () => {
      if (iframeRef.current) {
        const rect = iframeRef.current.getBoundingClientRect();
        console.log('onLenisScroll', rect.bottom < window.innerHeight);
        setIntroed(rect.bottom < window.innerHeight);
      }
    };
    if (lenis) {
      lenis.on('scroll', onLenisScroll);
      return () => lenis.off('scroll', onLenisScroll);
    }
    return () => { };
  }, [lenis, setIntroed]);

  if (!app) {
    return;
  }

  const contents = app.contents;

  return contents.customIntro ? (
    skipCustomIntro ? null : (
      <section className="llm__customIntro" aria-label="Intro">
        <iframe ref={iframeRef} className="llm__iframe" src={(
          typeof contents.customIntro === 'object' ?
            contents.customIntro['it'] :
            contents.customIntro
        )} sandbox="allow-scripts"></iframe>
      </section>
    )
  ) : (
    <section className="llm__intro" aria-label="Intro">
      <div className="llm-container">
        <motion.div variants={stagger} className="llm__intro-wrapper llm-row">
          <motion.div variants={motionItem} className="llm__intro-text llm-span-md-6">
            <div className="llm__intro-blob">
              <motion.div variants={motionBlob} initial="top" animate="bottom">
                <Blob />
              </motion.div>
            </div>
            {embedded ? (
              <motion.h2 variants={motionItem} className="llm__intro-title -llm-t-display-2">
                {contents.extendedWelcomeText}
              </motion.h2>
            ) : (
              <motion.h1 variants={motionItem} className="llm__intro-title -llm-t-display-2">
                {contents.extendedWelcomeText}
              </motion.h1>
            )}
          </motion.div>
          <motion.div variants={motionItem} className="llm__intro-hints llm-span-md-6 llm-span-lg-5">
            <Hints />
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};
