import { useLabel } from '@websolutespa/bom-mixer-hooks';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { useLlm } from '../../../useLlm/useLlm';
import { BusyIndicator } from '../BusyIndicator/busyindicator';
import { Chunk } from '../Chunk/chunk';

export type StreamProps = {
  index: number;
};

export const Stream: React.FC<StreamProps> = (props: StreamProps) => {
  const label = useLabel();
  const chunks = useLlm(state => state.chunks);
  return (
    <>
      {chunks && (
        <>
          <div
            className="llm__message llm__message--assistant llm-row"
            role="region"
            aria-label={label('llm.answer')}
          >
            <AnimatePresence>
              {chunks.map((chunk, c) => (
                <div key={`llm-message-${props.index}-chunk-${c}`} className={`llm__inner llm__inner--${chunk.type}`} data-message-index={props.index}>
                  <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                  >
                    <Chunk item={chunk} chunkIndex={c} index={props.index} total={props.index + 1} />
                  </motion.div>
                </div>
              ))}
              <BusyIndicator />
            </AnimatePresence>
          </div>
        </>
      )}
    </>
  );
};
