import { useLabel } from '@websolutespa/bom-mixer-hooks';
import { Text } from '../Text/text';

export const FormRecapError = () => {
  const label = useLabel();

  return (
    <Text body={label('llm.formRecapError')} />
  );
};
