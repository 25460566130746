import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './app';
import { LlmInstance, LlmOptions } from './types';

export function bomLlm(props: LlmOptions): LlmInstance | undefined {
  if (!document) {
    return;
  }

  const body = document.querySelector('body');
  if (!body) {
    return;
  }

  const node = document.createElement('div');
  node.setAttribute('id', 'bom-llm');
  body.appendChild(node);

  const llm = {
    open: () => {
      console.log('unitialized');
    },
    dispose: () => {
      console.log('unitialized');
    },
  };

  const root = ReactDOM.createRoot(node);
  root.render(
    <React.StrictMode>
      <App {...props} instance={llm} />
    </React.StrictMode>
  );

  const renderEmbed = (node: Element) => {
    if (!node.hasAttribute('data-llm')) {
      node.setAttribute('data-llm', '');
      const root = ReactDOM.createRoot(node);
      root.render(
        <React.StrictMode>
          <App {...props} embedded={true} />
        </React.StrictMode>
      );
    }
  };

  // observe embeds
  const onIntersection = (node: Element) => {
    // console.log('onIntersection', node);
    renderEmbed(node);
  };
  const targetElement = document;
  const intersectionObserver = observeIntersections(targetElement, onIntersection);
  const embeds = Array.from(document.querySelectorAll('llm-embed')).filter(element => !element.hasAttribute('data-llm'));
  embeds.forEach(element => intersectionObserver.observe(element));
  const mutationObserver = observeMutations(targetElement, ({ addedNodes, removedNodes }) => {
    // console.log('addedNodes', addedNodes);
    const addedElements = Array.from(addedNodes).filter(x => x.nodeType === Node.ELEMENT_NODE) as Element[];
    // console.log('addedElements', addedElements);
    const elements = queryElements(addedElements);
    // console.log('elements', elements);
    elements.forEach(element => intersectionObserver.observe(element));
  });
  const elements = queryElements(targetElement);
  elements.forEach(element => intersectionObserver.observe(element));
  const dispose = () => {
    intersectionObserver.disconnect();
    mutationObserver.disconnect();
  };
  llm.dispose = dispose;
  return llm;
}

if (typeof window !== 'undefined') {
  (window as unknown as {
    bomLlm: typeof bomLlm;
  }).bomLlm = bomLlm;
}

function observeIntersections(targetElement = document, callback = (target: Element) => { }) {
  let observer;
  if ('IntersectionObserver' in window) {
    const observerOptions = {
      root: targetElement.parentElement ? targetElement.parentElement : targetElement,
      rootMargin: '50px',
      threshold: [0.01, 0.99],
    };
    observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          callback(entry.target);
          observer.unobserve(entry.target);
        }
      });
    }, observerOptions);
  } else {
    observer = {
      observe: callback,
      disconnect: () => { },
    };
  }
  return observer;
}

function observeMutations(targetElement = document, callback = (mutation: MutationRecord) => { }) {
  let observer;
  if ('MutationObserver' in window) {
    const config = { attributes: false, childList: true, subtree: true };
    observer = new MutationObserver((mutationList, observer) => {
      for (const mutation of mutationList) {
        if (mutation.type === 'childList') {
          callback(mutation);
        }
        /*
        else if (mutation.type === 'attributes') {
          console.log(`The ${mutation.attributeName} attribute was modified.`);
        }
        */
      }
    });
    observer.observe(targetElement, config);
  } else {
    observer = {
      disconnect: () => { },
    };
  }
  return observer;
}

function queryElements(elements: Element[] | Element | Document) {
  const filteredElements: Element[] = [];
  const iterable: Iterable<Element> | Element[] = (
    Array.isArray(elements) ?
      elements :
      (
        isIterable<Element>(elements) ?
          elements :
          [elements as Element]
      )
  );
  for (const element of iterable) {
    const childElements = element.querySelectorAll('llm-embed');
    for (const childElement of childElements) {
      if (!childElement.hasAttribute('data-llm')) {
        filteredElements.push(childElement);
      }
    }
    if (
      element.tagName &&
      element.tagName.toLowerCase() === 'llm-embed' &&
      !element.hasAttribute('data-llm')
    ) {
      filteredElements.push(element);
    }
  }
  return filteredElements;
}

function isIterable<T>(element: unknown): element is Iterable<T> {
  return element != null && typeof (element as Iterable<T>)[Symbol.iterator] === 'function';
}
